import { MouseEventHandler, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useConfigsContext } from '@/context/ConfigsContext'
import { postLikeRoom, postUnlikeRoom, Room } from '@/services/cms'

export function useLikedRoom(roomId: Room['id'], href: string, initialIsLiked: Room['is_liked']) {
  const router = useRouter()
  const { credentials } = useConfigsContext()
  const htcToken = credentials?.htcToken
  const [isLiked, setisLiked] = useState(initialIsLiked)

  const handleClickLike: MouseEventHandler = async (e) => {
    e.stopPropagation()
    // for the logged-in user
    if (htcToken) {
      if (isLiked) {
        const results = await postUnlikeRoom(roomId, htcToken)
        if (results?.status === 200) {
          setisLiked(false)
        }
      } else {
        const results = await postLikeRoom(roomId, htcToken)
        if (results?.status === 200) {
          setisLiked(true)
        }
      }
    } else {
      // guest flow
      router.push(href)
    }
  }

  return {
    isLiked,
    handleClickLike,
  }
}
