import {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogPrimitiveContent,
  DialogTitle,
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority'
import { ComponentProps, DialogHTMLAttributes, MouseEventHandler, ReactNode } from 'react'
import IconClose from '@/components/assets/icon_close.svg'

type ViverseDialogProps = ComponentProps<typeof Dialog> & {
  dialogTrigger: ReactNode
  contentClassName?: string
  childrenClassName?: string
  title?: string
  onClose?: MouseEventHandler<HTMLButtonElement>
  isOpened?: boolean
}

const dialogVariants = cva('bg-[#1D1D24]', {
  variants: {
    variant: {
      default: '',
      overlay: 'bg-[#000000B2]',
      content:
        'w-max max-w-[calc(100%_-_40px)] max-h-[calc(100dvh_-_64px)] p-1 overflow-auto fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-50 grid rounded-2xl gap-5 border-2 border-vive-primary-70 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] lg:min-w-[600px]',
      // 'p-1 overflow-hidden fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50 grid w-fit min-w-[312px] min-h-[180px] max-h-[460px] 2xl:min-w-[448px] 2xl:min-h-[232px] 2xl:max-h-[468px] rounded-2xl gap-5 border-2 border-vive-primary-70 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
      title:
        'mx-5 py-5 h-[62px] 2xl:h-[70px] w-[calc(100%-48px)] flex items-center justify-between text-vive-white subheader-1 2xl:header-4 flex border-b-2 border-vive-primary-80 border-solid ',
      close: 'size-[24px]',
      children:
        'dialog-scrollbar px-5 py-0 w-full overflow-scroll flex flex-col items-center',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

function ViverseDialog({
  dialogTrigger,
  title = '',
  onClose,
  children,
  contentClassName,
  childrenClassName,
  ...props
}: ViverseDialogProps) {
  return (
    <Dialog {...props}>
      <DialogTrigger className="block size-max">{dialogTrigger}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay className={cn(dialogVariants({ variant: 'overlay' }))} />
        <DialogPrimitiveContent
          className={cn(dialogVariants({ variant: 'content' }), contentClassName)}
        >
          <DialogTitle className={cn(dialogVariants({ variant: 'title' }))}>
            <div className="size-[24px]"></div>
            {title}
            <DialogClose className={cn(dialogVariants({ variant: 'close' }))} onClick={onClose}>
              <IconClose></IconClose>
            </DialogClose>
          </DialogTitle>
          <div className={cn(dialogVariants({ variant: 'children' }), childrenClassName)}>{children}</div>
        </DialogPrimitiveContent>
      </DialogPortal>
    </Dialog>
  )
}

export default ViverseDialog
