import { Button, ButtonProps } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

export type ViverseButtonProps = Omit<ButtonProps, 'size' | 'variant'> & {
  variant?: 'default' | 'secondary' | 'extra' | 'filter' | 'filterActive' | 'filterTag'
  size?: 'large' | 'small' | 'fit' | 'extra' | 'filter' | 'filterTag'
}

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap disabled:pointer-events-none disabled:bg-vive-primary-80 disabled:shadow-none disabled:opacity-100',
  {
    variants: {
      variant: {
        /** Blue Button */
        default:
          'rounded-full text-vive-white bg-vive-brand-50 hover:bg-vive-brand-40 active:bg-vive-brand-60',
        /** Outline Button */
        secondary:
          'rounded-full shadow-[0px_0px_0px_1.5px_var(--neutral-20)_inset] text-vive-primary-20 bg-transparent hover:bg-vive-primary-60 hover:text-vive-white hover:shadow-[0px_0px_0px_1.5px_var(--neutral-white)_inset] active:bg-transparent active:text-vive-primary-40 active:shadow-[0px_0px_0px_1.5px_var(--neutral-40)_inset]',
        /** Gradient Button */
        extra:
          'rounded-full text-vive-white bg-gradient-button hover:bg-gradient-button-hover active:bg-gradient-button-active disabled:opacity-40',
        /** Filter Button */
        filter:
          'subheader-2 ml:subheader-1 gap-[6px] ml:gap-[8px] rounded-full bg-vive-primary-70 text-vive-primary-20 ml:text-vive-primary-20 hover:bg-vive-primary-60 hover:text-vive-white',
        filterActive:
          'subheader-2 ml:subheader-1 gap-[6px] ml:gap-[8px] rounded-full bg-vive-primary-70 text-primary shadow-[0px_0px_0px_1.8px_var(--primary)_inset] hover:bg-vive-primary-60 hover:text-vive-brand-40 hover:shadow-[0px_0px_0px_1.8px_var(--brand-vive-40)_inset] ml:text-primary',
        filterTag:
          'subheader-2 ml:subheader-1 gap-[8px] ml:gap-[16px] rounded-full shadow-[0px_0px_0px_2px_var(--neutral-70)_inset] text-vive-primary-20 ml:text-vive-primary-20 bg-transparent hover:shadow-[0px_0px_0px_2px_var(--neutral-50)_inset]',
      },
      size: {
        /** Under 1440: mw: 160px, p: 20px 6px / Above 1440: mw: 200px, p: 20px 10px. Eg. Landing - Accept invitation*/
        large: 'min-w-[160px] px-[20px] py-[6px] 2xl:min-w-[200px] 2xl:py-[10px] button',
        /** Under 768: mw: 120px, p: 20px 6px / Above 768: mw: 160px, p: 20px 6px / Above 1440: mw: 200px, p: 20px 10px. Eg. Landing - View all */
        small:
          'min-w-[120px] px-[20px] py-[6px] md:min-w-[160px] 2xl:min-w-[200px] 2xl:py-[10px] button',
        /** Under 768: p: 10px 28px / Above 1440: p: 13px 28px. Eg. Landing MKTBanner - Learn more */
        extra:
          'px-7 leading-10 h-10 2xl:leading-[56px] 2xl:h-[56px] font-bold text-lg 2xl:text-[26px] button',
        fit: 'w-fit min-w-[120px] px-[20px] py-[6px] button',
        filter: 'w-fit px-[16px] py-[4px] ml:px-[26px] ml:py-[8px] button',
        filterTag: 'w-fit px-[16px] py-[4px] ml:px-[24px] ml:py-[6px] button',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'fit',
    },
  },
)

const ViverseButton = forwardRef<HTMLButtonElement, ViverseButtonProps>(function ViverseButton(
  { children, className, variant = 'default', size = 'fit', ...rest },
  ref,
) {
  return (
    <Button
      ref={ref}
      variant="ghost"
      size="custom"
      className={cn(buttonVariants({ variant, size, className }), className)}
      type="button"
      {...rest}
    >
      {children}
    </Button>
  )
})

export default ViverseButton
