import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp'
import { InputProps } from '@/components/ui/input'

interface ViverseInputOTPProps extends InputProps {}

function ViverseInputOTP(props: ViverseInputOTPProps) {
  return (
    <div {...props}>
      <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS_AND_CHARS} containerClassName="w-max">
        <InputOTPGroup>
          <InputOTPSlot index={0} className="mr-2 border-b-2 border-vive-primary-80 text-white" />
          <InputOTPSlot index={1} className="mr-2 border-b-2 border-vive-primary-80 text-white" />
          <InputOTPSlot index={2} className="mr-2 border-b-2 border-vive-primary-80 text-white" />
          <InputOTPSlot index={3} className="mr-2 border-b-2 border-vive-primary-80 text-white" />
          <InputOTPSlot index={4} className="mr-2 border-b-2 border-vive-primary-80 text-white" />
          <InputOTPSlot index={5} className="border-b-2 border-vive-primary-80 text-white" />
        </InputOTPGroup>
      </InputOTP>
      <div className="body-4">Enter 6 numbers</div>
    </div>
  )
}

export default ViverseInputOTP
